import {ICategory, ICategoryListItem} from '../types/category';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {IFilterConfigDTO} from '../types/galleryTypes';
import {RouterPrefix} from '@wix/wixstores-client-core';

export type CategoriesData = {
  selectedCategory: ICategory;
  categoryListHeader: ICategoryListItem;
  categoryList: ICategoryListItem[];
};
export class CategoriesService {
  public visibleCategories: ICategoryListItem[];
  private readonly currentCategory: ICategory;
  private readonly allCategories: ICategoryListItem[];
  private readonly allProductsCategoryId: string;
  private readonly siteStore: SiteStore;
  private categoryListConfig: IFilterConfigDTO;

  constructor({
    currentCategory,
    allCategories,
    allProductsCategoryId,
    siteStore,
  }: {
    currentCategory: ICategory;
    allCategories: ICategoryListItem[];
    allProductsCategoryId: string;
    siteStore: SiteStore;
  }) {
    this.currentCategory = currentCategory;
    this.allCategories = allCategories;
    this.allProductsCategoryId = allProductsCategoryId;
    this.siteStore = siteStore;
  }

  private getSubcategories(/*categoryId: string*/): ICategoryListItem[] {
    return null;
  }

  private getParent(/*categoryId: string*/): ICategoryListItem {
    return null;
  }

  private getRootLevelCategories() {
    return this.visibleCategories;
  }

  /* istanbul ignore next */
  private getCategoryList() {
    const subcategories = this.getSubcategories(/*this.currentCategory.id*/);
    if (subcategories) {
      return subcategories;
    }

    const parent = this.getParent(/*this.currentCategory.id*/);
    if (parent) {
      return this.getSubcategories(/*parent.id*/);
    }

    return this.getRootLevelCategories();
  }

  /* istanbul ignore next */
  private getCategoryListHeader() {
    const subcategories = this.getSubcategories(/*this.currentCategory.id*/);
    if (subcategories) {
      return this.currentCategory;
    }

    const parent = this.getParent(/*this.currentCategory.id*/);
    if (parent) {
      return parent;
    }

    return null;
  }

  public getProps(): CategoriesData {
    return {
      selectedCategory: this.currentCategory,
      categoryListHeader: this.getCategoryListHeader(),
      categoryList: this.getCategoryList(),
    };
  }

  public updateVisibleCategories({
    categoryListConfig,
    shouldUseCategoryListConfig,
  }: {
    categoryListConfig?: IFilterConfigDTO;
    shouldUseCategoryListConfig: boolean;
  }) {
    if (categoryListConfig) {
      this.categoryListConfig = categoryListConfig;
    }

    let categories = [...this.allCategories];
    if (shouldUseCategoryListConfig && this.categoryListConfig !== undefined) {
      const sortedIds = [];
      const selectedCategoriesFromConfig = this.categoryListConfig.selected.reduce((obj, item) => {
        obj[item.id] = null;
        sortedIds.push(item.id);
        return obj;
      }, {});

      categories = categories
        .filter((c) => c.visible && c.id in selectedCategoriesFromConfig)
        .sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id));
    } else {
      categories = categories.filter((c) => c.visible).sort((a, b) => a.name.localeCompare(b.name));
      const defaultCategoryIndex = categories.findIndex((c) => c.id === this.allProductsCategoryId);
      if (defaultCategoryIndex !== -1) {
        categories.unshift(categories.splice(defaultCategoryIndex, 1)[0]);
      }
    }

    this.visibleCategories = categories.map((c) => {
      return {
        ...c,
        categoryUrl: `${this.siteStore.location.baseUrl}/${RouterPrefix.CATEGORY}/${c.slug}`,
      };
    });
  }

  public getCategoryBySlug(slug?: string): ICategory {
    return (this.allCategories.find((c) => c.slug === slug) as ICategory) ?? (this.visibleCategories[0] as ICategory);
  }
}
